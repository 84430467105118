import {
  Component,
  Input,
  HostListener,
  TemplateRef,
  ViewChild,
  ElementRef,
  OnInit,
} from '@angular/core';
import {
  DayPilot,
  DayPilotSchedulerComponent,
  DayPilotCalendarComponent,
} from 'daypilot-pro-angular';
import {
  NgbModal,
  NgbModalConfig
} from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/services/apiservice.service';
import { DataService } from 'src/services/dataservice.service';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { LandingPageComponent } from '../landing-page/landing-page.component';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import Inputmask from "inputmask";
import { HttpClient } from "@angular/common/http";
import { saveAs } from 'file-saver';
import { calendarEvent } from '../../models/calendarevent';
import { calendarEventList } from '../../models/calendareventlist'
import { createResponse } from '../../models/checklist';
import { Order } from '../../models/order';
import { createMask } from '@ngneat/input-mask';
import { location } from 'src/models/location';
import englishJson from '../../assets/i18n/en.json';
import dutchJson from '../../assets/i18n/du.json';
import spanishJson from '../../assets/i18n/es.json';

@Component({
  selector: 'app-working-hours',
  templateUrl: './working-hours.component.html',
  styleUrls: ['./working-hours.component.scss'],
})
export class WorkingHoursComponent implements OnInit {
  @ViewChild('content', { static: false }) private contentRef: TemplateRef<any>;
  @ViewChild('update') private updateRef: TemplateRef<Object>;
  @ViewChild('startnow') private startnow: TemplateRef<Object>;
  @ViewChild('projectInput') searchElement: ElementRef;
  @ViewChild('timeInput') timeInput: ElementRef;
  closeResult: string;
  columns: any = [];
  @ViewChild('calendar')
  timesheet!: DayPilotSchedulerComponent;
  args: DayPilot.SchedulerTimeRangeSelectedArgs;
  employees: any[] = [];
  calendar!: DayPilotCalendarComponent;
  employee: any;
  popup_flag: boolean = true;
  updatepopup_flag: boolean = true;
  customerID: string | null;
  eventId: string;
  events: any = [];
  registrationForm: any;
  workstationid: string | null;
  start_date: any;
  end_date: any;
  events_data: any;
  selected_date: string;
  updateForm: any;
  dropdown: any = [];
  selected_date_display: string;
  show_checkbox: boolean = false;
  allow_greensubcat: boolean;
  attachment_flag: any;
  green_categories: any;
  lastDayOfTheWeek: any;
  slider_values: any = [];
  public dutch_days = [
    'zondag',
    'maandag',
    'dinsdag',
    'woensdag',
    'donderdag',
    'vrijdag',
    'zaterda',
  ];
  public english_days = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];

  public spanish_days = [
    'domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado',
  ]
  statusForm: any;
  currentlang: string | null;
  @Input() language: string;
  green_eventdata: any;
  orders: any;
  active_order: any;
  activityForm: any;
  name: string | null;
  innerWidth: number;
  currentuser: any;
  kind_value: any;
  Selected_Catgorie: any;
  week_number: number;
  Overall_Total: string;
  project_list: any = [];
  projects: any = [];
  Table_View: any = [];
  column_total: any = [];
  year: any;
  ShowRemarks: boolean;
  unsubscribe: any;
  subscription: any;
  placeholder: any;
  splitted_items: any;
  dropdown_values: any = [];
  values_b: any = [];
  SelectedCategory: any;
  ExtraValue: any;
  input_mask: string | null;
  input_desc: string | null;
  input: any;
  input_item: any;
  todayDetails: any;
  timeInputStatus: Inputmask.Instance;
  timeMask = createMask<Date>({
    alias: 'datetime',
    inputFormat: 'HH:MM',
  });
  locationForm: any;
  invalidTime: boolean;
  workLocations: any[] = [];
  selectBox: any;
  invalidDate: boolean;
  role: string | null;
  defaultDropDownValues: any;
  pedefaultvalue = 'PE';
  public duarionTime: any;
  public fromStartTime: any;
  public toEndTime: any;
  public oldCalenderEvents: any;
  public numberOfDaysHistory: number;
  projectExtentionValues = ['PE', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  config: DayPilot.CalendarConfig = {
    heightSpec: 'Fixed',
    height: this.getHeight(),
    timeFormat: 'Clock24Hours',
    cellDuration: 15,
    showHeader: false,
    viewType: 'Resources',
    cellHeight: 22,
    allowEventOverlap: false,
    columnMarginRight: 0,
    columns: this.columnss('1'),
    eventResizeHandling: "Disabled",
    onEventClicked: async (args) => {
      this.updateForm.reset({});
      this.registrationForm.reset({});
      this.duarionTime = "00:15"
      let s_day: any = args.e.data.start.getDay();
      let s_month: any = args.e.data.start.getMonth() + 1;
      let s_year = args.e.data.start.getYear();
      s_day < 10 ? (s_day = '0' + s_day) : (s_day = s_day);
      s_month < 10 ? (s_month = '0' + s_month) : (s_month = s_month);
      let start_date = s_day + '-' + s_month + '-' + s_year.toString();
      let e_day: any = args.e.data.end.getDay();
      let e_month: any = args.e.data.end.getMonth() + 1;
      let e_year = args.e.data.end.getYear();
      e_day < 10 ? (e_day = '0' + e_day) : (e_day = e_day);
      e_month < 10 ? (e_month = '0' + e_month) : (e_month = e_month);
      let end_date = e_day + '-' + e_month + '-' + e_year.toString();
      let shour: any = args.e.data.start.getHours();
      shour < 10 ? (shour = '0' + shour) : (shour = shour);
      let smin: any = args.e.data.start.getMinutes();
      smin < 10 ? (smin = '0' + smin) : (smin = smin);
      let start_time = shour + ':' + smin;
      let ehour: any = args.e.data.end.getHours();
      ehour < 10 ? (ehour = '0' + ehour) : (ehour = ehour);
      let emin: any = args.e.data.end.getMinutes();
      emin < 10 ? (emin = '0' + emin) : (emin = emin);
      let end_time = ehour + ':' + emin;
      this.fromStartTime = start_time;
      this.toEndTime = end_time
      if (args.e.data.backColor === '#24597A') {
        this.updatepopup_flag = true;
        const toTotalMinutes = (+ehour * 60) + (+emin);
        const fromTotalMinutes = (+shour) * 60 + (+smin);
        const durationMinutes = (+toTotalMinutes) - (+fromTotalMinutes);
        const hours = Math.floor(+durationMinutes / 60);
        let minutes: any = +durationMinutes % 60;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        let nthours = hours < 10 ? '0' + hours : hours;
        this.duarionTime = nthours + ':' + minutes;
        this.fromStartTime = start_time;
        this.toEndTime = end_time;
        this.updateForm.patchValue({
          id: args.e.data.id,
          delete_checkbox: false,
          type: 'working_hours',
          project: args.e.data.eventDetailID,
          event: args.e.data.eventDetailID,
          from_date: start_date,
          to_date: end_date,
          from_time: start_time,
          to_time: end_time

        });
        if (args.e.data.remarks) {
          this.ShowRemarks = true;
          this.updateForm.patchValue({
            note: args.e.data.remarks,
          });
        } else {
          this.ShowRemarks = false;
        }
      } else {
        this.updatepopup_flag = false;
        const toTotalMinutes = (+ehour * 60) + (+emin);
        const fromTotalMinutes = (+shour) * 60 + (+smin);
        const durationMinutes = (+toTotalMinutes) - (+fromTotalMinutes);
        const hours = Math.floor(+durationMinutes / 60);
        let minutes: any = +durationMinutes % 60;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        let nthours = hours < 10 ? '0' + hours : hours;
        this.duarionTime = nthours + ':' + minutes
        this.updateForm.patchValue({

          id: args.e.data.id,
          delete_checkbox: false,
          categorie: args.e.data.categorie,
          type: 'project',
          // project: args.e.data.text.split(' ')[0],
          project: args.e.data.text.split(' ')[0].split('-')[0],
          projectExtention: args.e.data.text.split(' ')[0].split('-')[1] ? args.e.data.text.split(' ')[0].split('-')[1] : '',
          from_date: start_date,
          to_date: end_date,
          from_time: start_time,
          to_time: end_time,
          to_hour: ehour,
          to_minute: emin,
          from_hour: shour,
          from_minute: smin
        });
        if (args.e.data.remarks) {
          this.setFormFields(args.e.data.remarks, 'update');
          // this.ShowRemarks = true;
          this.updateForm.patchValue({
            mail: args.e.data.remarks,
          });
        }
        if (args.e.data.meerwerk) {
          this.updateForm.patchValue({
            remark: args.e.data.meerwerk,
          });
        }

        else {
          this.ShowRemarks = false;
        }
      }
      this.show_checkbox = false;
      this.open(this.updateRef, args, this.searchElement);
    },
    onTimeRangeSelected: async (args) => {
      this.updateForm.reset({});
      this.registrationForm.reset({});
      this.duarionTime = "00:15"
      let s_day: any = args.start.getDay();
      let s_month: any = args.start.getMonth() + 1;
      let s_year = args.start.getYear();
      s_day < 10 ? (s_day = '0' + s_day) : (s_day = s_day);
      s_month < 10 ? (s_month = '0' + s_month) : (s_month = s_month);
      let start_date = s_day + '-' + s_month + '-' + s_year.toString();
      let e_day: any = args.end.getDay();
      let e_month: any = args.end.getMonth() + 1;
      let e_year = args.end.getYear();
      e_day < 10 ? (e_day = '0' + e_day) : (e_day = e_day);
      e_month < 10 ? (e_month = '0' + e_month) : (e_month = e_month);
      let end_date = e_day + '-' + e_month + '-' + e_year.toString();
      let shour: any = args.start.getHours();
      shour < 10 ? (shour = '0' + shour) : (shour = shour);
      let smin: any = args.start.getMinutes();
      smin < 10 ? (smin = '0' + smin) : (smin = smin);
      let start_time = shour + ':' + smin;
      let ehour: any = args.end.getHours();
      ehour < 10 ? (ehour = '0' + ehour) : (ehour = ehour);
      let emin: any = args.end.getMinutes();
      emin < 10 ? (emin = '0' + emin) : (emin = emin);
      let end_time = ehour + ':' + emin;
      this.popup_flag = true;
      this.fromStartTime = start_time;
      this.toEndTime = end_time;
      this.registrationForm.patchValue({
        from_date: start_date,
        to_date: end_date,
        from_time: start_time,
        to_time: end_time
      });
      this.open(this.contentRef, args, '');
    },
  };
  constructor(
    private http: HttpClient,
    private Modalconfig: NgbModalConfig,
    private DataService: DataService,
    public LandingPageComponent: LandingPageComponent,
    private modalService: NgbModal,
    public fb: UntypedFormBuilder,
    private apiservice: ApiService,
    private toastrService: ToastrService,
  ) {
    this.currentuser = localStorage.getItem('current_user');
    this.currentuser && this.currentuser != undefined
      ? (this.currentuser = JSON.parse(this.currentuser))
      : '';
    this.customerID = localStorage.getItem('customerID');
    this.workstationid = localStorage.getItem('workstationID');
    this.currentlang = localStorage.getItem('language');
    this.name = localStorage.getItem('smartbox_name');
    this.year = new Date().getFullYear().toString().slice(-2);
    this.activities();
    this.activityForm = this.fb.group({
      event: ['', [Validators.required]],
    });
    this.green_categories = localStorage.getItem("greencategories");
    (this.green_categories) ? this.green_categories = JSON.parse(this.green_categories) : '';
    this.Modalconfig.backdrop = 'static';
    this.Modalconfig.keyboard = false;
    this.role = localStorage.getItem("role");
    this.registrationForm = this.fb.group({
      project: [],
      projectExtention: [],
      event: [],
      categorie: [],
      note: [],
      remark: [],
      mail: ['', []],
      from_date: ['', [Validators.required]],
      from_time: ['', [Validators.required]],
      to_date: ['', [Validators.required]],
      to_time: ['', [Validators.required]],
    });
    this.updateForm = this.fb.group({
      type: [],
      categorie: [],
      delete_checkbox: [false],
      id: ['', [Validators.required]],
      project: [],
      projectExtention: [],
      event: [],
      note: [],
      mail: ['', []],
      remark: [],
      from_date: ['', [Validators.required]],
      from_time: ['', [Validators.required]],
      to_date: ['', [Validators.required]],
      to_time: ['', [Validators.required]],
    });
    this.statusForm = this.fb.group({
      event: ['', [Validators.required]],
      note: [''],
    });

    var dateObj = new Date();
    var month = dateObj.getMonth() + 1;
    var day = dateObj.getDate();
    var year = dateObj.getFullYear();

    this.selected_date_display = day + '/' + month + '/' + year;
    this.selected_date = year + '-' + month + '-' + day;
    this.getDays();
    this.setmodal_flag('2', this.searchElement);
    this.input_mask = localStorage.getItem("input_mask");
    this.input_desc = localStorage.getItem('mask_descp');
  }

  getinput() {
    if (this.input_mask != null && this.input_mask != undefined) {
      this.input_item = this.input.isValid();
    }
  }

  updateLocation(index: number) {
    let payload;
    let location = this.locationForm.value["location" + index];
    if (this.locationForm.valid) {
      if (location.trim() !== '') {
        payload = {
          "userID": this.currentuser.userID,
          "date": this.slider_values[index].date,
          "workLocation": location
        }
        let locations: any = localStorage.getItem("locations");
        (locations) ? locations = JSON.parse(locations) : '';
        if (locations) {
          let flag = 1;
          for (let i = 0; i < locations.length; i++) {
            if (locations[i].date === this.slider_values[index].date) {
              locations.splice(i, 1);
              locations.push(payload);
              flag = 0;
              break;
            } else {
              flag = 1;
            }
          }
          if (flag === 1) {
            locations.push(payload);
            localStorage.setItem("locations", JSON.stringify(locations));
          } else {
            localStorage.setItem("locations", JSON.stringify(locations));
          }
        } else {
          var item: any = [];
          item.push(payload)
          localStorage.setItem("locations", JSON.stringify(item));
        }
        this.apiservice.updateWorkLocation(payload).subscribe();
        this.Table_View.forEach((item: any) => {
          if (item.name === 'LOCATION') {
            item.selectBoxArray[index].selectedValue = location;
          }
        });
      }
    }
  }

  private getHeight() {
    let height = window.innerHeight;
    if (500 <= height && height < 550) {
      let item = window.innerHeight / 3.3;
      return Math.ceil(item);
    } else if (550 <= height && height < 600) {
      let item = window.innerHeight / 2.55;
      return Math.ceil(item);
    } else if (600 <= height && height <= 650) {
      let item = window.innerHeight / 2.6;
      return Math.ceil(item);
    } else if (651 <= height && height <= 700) {
      let item = window.innerHeight / 2.5;
      return Math.ceil(item);
    } else if (700 < height && height <= 750) {
      let item = window.innerHeight / 2.66;
      return Math.ceil(item);
    } else if (750 < height && height <= 800) {
      let item = window.innerHeight / 2.3;
      return Math.ceil(item);
    } else if (800 < height && height <= 850) {
      let item = window.innerHeight / 2.2;
      return Math.ceil(item);
    } else if (850 < height && height <= 900) {
      let item = window.innerHeight / 2;
      return Math.ceil(item);
    } else {
      let item = window.innerHeight / 1.8;
      return Math.ceil(item);
    }
  }

  ngOnInit(): void {

    this.subscription = this.DataService.modalValue.subscribe(
      (item: string) => {
        item == 'true' ? this.open(this.startnow, '', '') : '';
      }
    );
    this.DataService.language.subscribe((item: string) => {
      item === 'en'
        ? (this.currentlang = 'English')
        : item === 'du' ? (this.currentlang = 'Dutch') : (this.currentlang = 'Spanish');
      this.language = this.currentlang;
      this.set_dates('2');
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // config: DayPilot.CalendarConfig = {
  //   heightSpec: 'Fixed',
  //   height: this.getHeight(),
  //   timeFormat: 'Clock24Hours',
  //   cellDuration: 15,
  //   showHeader: false,
  //   viewType: 'Resources',
  //   cellHeight: 22,
  //   allowEventOverlap: false,
  //   columnMarginRight: 0,
  //   columns: this.columnss('1'),
  //   eventResizeHandling :"Disabled",
  //   onEventClicked: async (args) => {
  //     let s_day: any = args.e.data.start.getDay();
  //     let s_month: any = args.e.data.start.getMonth() + 1;
  //     let s_year = args.e.data.start.getYear();
  //     s_day < 10 ? (s_day = '0' + s_day) : (s_day = s_day);
  //     s_month < 10 ? (s_month = '0' + s_month) : (s_month = s_month);
  //     let start_date = s_day + '-' + s_month + '-' + s_year.toString();
  //     let e_day: any = args.e.data.end.getDay();
  //     let e_month: any = args.e.data.end.getMonth() + 1;
  //     let e_year = args.e.data.end.getYear();
  //     e_day < 10 ? (e_day = '0' + e_day) : (e_day = e_day);
  //     e_month < 10 ? (e_month = '0' + e_month) : (e_month = e_month);
  //     let end_date = e_day + '-' + e_month + '-' + e_year.toString();
  //     let shour: any = args.e.data.start.getHours();
  //     shour < 10 ? (shour = '0' + shour) : (shour = shour);
  //     let smin: any = args.e.data.start.getMinutes();
  //     smin < 10 ? (smin = '0' + smin) : (smin = smin);
  //     let start_time = shour + ':' + smin;
  //     let ehour: any = args.e.data.end.getHours();
  //     ehour < 10 ? (ehour = '0' + ehour) : (ehour = ehour);
  //     let emin: any = args.e.data.end.getMinutes();
  //     emin < 10 ? (emin = '0' + emin) : (emin = emin);
  //     let end_time = ehour + ':' + emin;
  //     if (args.e.data.backColor === '#24597A') {
  //       this.updatepopup_flag = true;
  //       this.updateForm.patchValue({
  //         id: args.e.data.id,
  //         delete_checkbox: false,
  //         type: 'working_hours',
  //         project: args.e.data.eventDetailID,
  //         event: args.e.data.eventDetailID,
  //         from_date: start_date,
  //         to_date: end_date,
  //         from_time: start_time,
  //         to_time: end_time,
  //       });

  //       if (args.e.data.remarks) {
  //         this.ShowRemarks = true;
  //         this.updateForm.patchValue({
  //           note: args.e.data.remarks,
  //         });
  //       } else {
  //         this.ShowRemarks = false;
  //       }
  //     } else {
  //       this.updatepopup_flag = false;
  //       this.updateForm.patchValue({

  //         id: args.e.data.id,
  //         delete_checkbox: false,
  //         categorie: args.e.data.categorie,
  //         type: 'project',
  //         // project: args.e.data.text.split(' ')[0],
  //         project: args.e.data.text.split(' ')[0].split('-')[0],
  //         projectExtention:args.e.data.text.split(' ')[0].split('-')[1]?args.e.data.text.split(' ')[0].split('-')[1]:'',
  //         from_date: start_date,
  //         to_date: end_date,
  //         from_time: start_time,
  //         to_time: end_time,
  //       });
  //       if (args.e.data.remarks) {
  //         this.setFormFields(args.e.data.remarks, 'update');
  //         // this.ShowRemarks = true;
  //         this.updateForm.patchValue({
  //           mail: args.e.data.remarks,
  //         });
  //       } else {
  //         this.ShowRemarks = false;
  //       }
  //     }
  //     this.show_checkbox = false;
  //     this.open(this.updateRef, args, this.searchElement);
  //   },
  //   onTimeRangeSelected: async (args) => {
  //     let s_day: any = args.start.getDay();
  //     let s_month: any = args.start.getMonth() + 1;
  //     let s_year = args.start.getYear();
  //     s_day < 10 ? (s_day = '0' + s_day) : (s_day = s_day);
  //     s_month < 10 ? (s_month = '0' + s_month) : (s_month = s_month);
  //     let start_date = s_day + '-' + s_month + '-' + s_year.toString();
  //     let e_day: any = args.end.getDay();
  //     let e_month: any = args.end.getMonth() + 1;
  //     let e_year = args.end.getYear();
  //     e_day < 10 ? (e_day = '0' + e_day) : (e_day = e_day);
  //     e_month < 10 ? (e_month = '0' + e_month) : (e_month = e_month);
  //     let end_date = e_day + '-' + e_month + '-' + e_year.toString();
  //     let shour: any = args.start.getHours();
  //     shour < 10 ? (shour = '0' + shour) : (shour = shour);
  //     let smin: any = args.start.getMinutes();
  //     smin < 10 ? (smin = '0' + smin) : (smin = smin);
  //     let start_time = shour + ':' + smin;
  //     let ehour: any = args.end.getHours();
  //     ehour < 10 ? (ehour = '0' + ehour) : (ehour = ehour);
  //     let emin: any = args.end.getMinutes();
  //     emin < 10 ? (emin = '0' + emin) : (emin = emin);
  //     let end_time = ehour + ':' + emin;
  //     this.popup_flag = true;
  //     this.registrationForm.patchValue({
  //       from_date: start_date,
  //       to_date: end_date,
  //       from_time: start_time,
  //       to_time: end_time,
  //     });
  //     this.open(this.contentRef, args, '');
  //   },
  // };

  @HostListener('window:resize', ['$event'])
  onResize() {
    let height = window.innerHeight;
    if (500 <= height && height < 550) {
      let item = window.innerHeight / 3.3;
      this.config.height = Math.ceil(item);
    } else if (550 <= height && height < 600) {
      let item = window.innerHeight / 2.55;
      this.config.height = Math.ceil(item);
    } else if (600 <= height && height <= 650) {
      let item = window.innerHeight / 2.6;
      this.config.height = Math.ceil(item);
    } else if (651 <= height && height <= 700) {
      let item = window.innerHeight / 2.5;
      this.config.height = Math.ceil(item);
    } else if (700 < height && height <= 750) {
      let item = window.innerHeight / 2.66;
      this.config.height = Math.ceil(item);
    } else if (750 < height && height <= 800) {
      let item = window.innerHeight / 2.3;
      this.config.height = Math.ceil(item);
    } else if (800 < height && height <= 850) {
      let item = window.innerHeight / 2.2;
      this.config.height = Math.ceil(item);
    } else if (850 < height && height <= 900) {
      let item = window.innerHeight / 2;
      this.config.height = Math.ceil(item);
    } else {
      let item = window.innerHeight / 1.8;
      this.config.height = Math.ceil(item);
    }
  }

  activities() {
    this.apiservice.getgreenactivities().subscribe(
      async (res: any) => {
        await this.getCategories(this.currentuser.fkCustomerID);
        if (res.response) {
          this.attachment_flag = res.response.allow_attachments;
          if (
            res.response.numberOfGreenActivities != '1' ||
            res.response.numberOfGreenActivities != 1
          ) {
            this.allow_greensubcat = true;
          } else {
            this.allow_greensubcat = false;
          }
        }
      },
      (error: any) => {
        this.toastrService.error('Something went wrong!', 'Error!');
      }
    );
  }

  Set_CategorieValue(item: any) {
    this.Selected_Catgorie = item.target.value;
  }

  async setFormFields(data: any, flag: string) {
    this.SelectedCategory = this.green_categories?.find((element: any) => (element.extraLevel.includes(data) || +element.value === +data));
    // console.log(this.SelectedCategory, 'this.SelectedCategory ')
    // this.green_categories.forEach((element: any) => {
    //   if (element.value == data || element.extraLevel?.includes(data)) {
    //     this.SelectedCategory = element;
    //   }
    // });
    let item = this.SelectedCategory;
    if (this.SelectedCategory?.preventSkipExtraLevel == '1') {
      this.Modalconfig.backdrop = 'static';
      this.Modalconfig.keyboard = false;
    } else {
      this.Modalconfig.backdrop = true;
      this.Modalconfig.keyboard = true;
    }
    this.dropdown_values = [];
    this.placeholder = [];
    this.values_b = [];
    if (
      this.SelectedCategory?.extraLevel != null &&
      this.SelectedCategory?.extraLevel != ''
    ) {
      if (
        this.SelectedCategory.extraLevel.includes('mailto') &&
        this.SelectedCategory.extraLevel.includes('|')
      ) {
        let mail_flag;
        this.splitted_items = item.extraLevel.split('|');
        var format = /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g;
        if (this.splitted_items?.length > 0) {
          this.splitted_items.forEach((element: any) => {
            if (!format.test(element)) {
              this.placeholder.push(element);
              const fields = this.fb.group({
                [element]: [],
              });
            } else {
              mail_flag = true;
            }
          });
        }
        if (mail_flag == true) {
          this.splitted_items.forEach((element: any) => {
            var arr_Str = element.split(/[<>]/);
            arr_Str.forEach((z: any) => {
              this.values_b.push(z);
            });
            this.values_b.forEach((y: any) => {
              var regex = /^[A-Za-z0-9&; ]+$/;
              var isValid = regex.test(y);
              if (isValid && y != '' && y != ';') {
                let t = y.substring(0, y.indexOf(' &'));
                t = t == '' ? y : t;
                let flag;
                flag = new RegExp('\\b' + 'Stuur' + '\\b').test(t);
                flag == true ? this.dropdown_values.push(t) : '';
              }
            });
          });
        }
      } else if (
        (!item.extraLevel.includes('mailto') &&
          item.extraLevel.includes(';')) ||
        item.extraLevel.includes('|')
      ) {
        let temp = item.extraLevel.split('|');
        temp.forEach((element: any, index: any, object: any) => {
          if (element.includes(';')) {
            this.dropdown_values = element.split(';');
          }
          if (!element.includes(';')) {
            this.splitted_items = element.split(' ');
          }
        });
        if (this.splitted_items?.length > 0) {
          this.splitted_items.forEach((element: any) => {
            this.placeholder.push(element);
            const fields = this.fb.group({
              [element]: [],
            });
          });
        }
      } else if (
        !item.extraLevel.includes('mailto') &&
        !item.extraLevel.includes(';') &&
        item.extraLevel.includes('|')
      ) {
        this.splitted_items = item.extraLevel.split('|');
        if (this.splitted_items?.length > 0) {
          this.splitted_items.forEach((element: any) => {
            this.placeholder.push(element);
            const fields = this.fb.group({
              [element]: [],
            });
          });
        }
      } else {
        if (
          !item.extraLevel.includes('mailto') &&
          !item.extraLevel.includes(';') &&
          !item.extraLevel.includes('|')
        ) {
          this.placeholder.push(item.extraLevel);
          const fields = this.fb.group({
            [item.extraLevel]: [],
          });
        }
      }
      for (let item in this.placeholder) {
        if (flag === 'add') {
          this.registrationForm.addControl(
            this.placeholder[item],
            new FormControl('', Validators.required)
          );
        } else {
          this.updateForm.patchValue({
            [this.placeholder[item]]: this.ExtraValue,
          });
          this.updateForm.addControl(
            this.placeholder[item],
            new FormControl('', Validators.required)
          );
        }
      }
      if (flag == 'update' && this.dropdown_values.length > 0) {
        this.updateForm.patchValue({
          mail: data,
        });
      }
    }
    this.defaultDropDownValues = this.dropdown_values[0]
  }

  async getCategories(userid: any) {
    this.apiservice.getcategories(userid).subscribe(
      (res: any) => {
        if (res) {
          if (this.allow_greensubcat) {
            this.green_categories = res?.green?.filter((element: any) => {
              return element.hidden != '1';
            });
            this.dropdown = res.darkblue.filter((element: any) => {
              return element.hidden != '1';
            });
            this.kind_value = this.dropdown[0]?.value;
          }
        } else {
          this.toastrService.error('No categories!', 'Error!');
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  setDeleteCheckbox() {
    this.updateForm.patchValue({
      delete_checkbox: !this.updateForm.value.delete_checkbox
    });
  }

  deleteEvent() {
    this.show_checkbox = true;
    if (this.updateForm.value.delete_checkbox == true) {
      let data = {
        eventID: parseInt(this.updateForm.value.id),
      };
      this.apiservice.delete_event(data).subscribe(
        (data: createResponse) => {
          this.modalService.dismissAll();
          if (data.response == 'success') {
            this.updateForm.value.delete_checkbox = false;
            this.updateForm.patchValue({
              delete_checkbox: false
            });
            this.show_checkbox = false;
            this.getDays();
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
    }
  }

  getPDF(id: HTMLButtonElement) {
    const buttonId: any = id;
    buttonId.disabled = "true";
    let stemp = this.slider_values[6].date.split("-");
    let sdate = stemp[2] + '-' + stemp[1] + '-' + stemp[0];
    let e_temp = this.slider_values[0].date.split("-");
    let edate = e_temp[2] + '-' + e_temp[1] + '-' + e_temp[0];
    let s_date = sdate.replace(/(^|-)0+/g, "$1");
    let e_date = edate.replace(/(^|-)0+/g, "$1");
    //   let payload = {
    //     "userID": parseInt(this.currentuser.userID),
    //     "startDate": sdate.replace(/(^|-)0+/g, "$1"),
    //     "endDate": edate.replace(/(^|-)0+/g, "$1")
    // };

    const fileName = `IJB weekstaat ${this.currentuser.displayName} - ${stemp[0]} week ${this.week_number}.pdf`
    let URL = `https://smartbox-solutions.nl/app-attachments/IJB/generateChecklistPDF.php?userID=${this.currentuser.userID}&startDate=${e_date}&endDate=${s_date}&output_method=file`
    //  return URL;
    this.http.get(URL, { responseType: "arraybuffer" }).subscribe(
      (pdf) => {
        const blob = new Blob([pdf], { type: "application/pdf" });
        saveAs(blob, fileName);
      },
      err => {
        console.log(err);
      }
    );
  }

  async getEventTranslation(data: string) {
    if (data) {
      if (this.language === 'Dutch') {
        if (dutchJson.HOME.hasOwnProperty(data)) {
          return (dutchJson.HOME as any)[data]
        }
      } else if (this.language === 'English') {
        if (englishJson.HOME.hasOwnProperty(data)) {
          return (englishJson.HOME as any)[data]
        }
      } else {
        if (spanishJson.HOME.hasOwnProperty(data)) {
          return (spanishJson.HOME as any)[data]
        }
      }
    } else {
      return data;
    }
  }

  async getCalendarEvents(args: any) {
    this.events = [];
    if (args?.header) {
      let day: any = args?.header?.start.getDay();
      let month: any = args?.header?.start.getMonth() + 1;
      let year = args?.header?.start?.getYear();
      (day < 10) ? (day = '0' + day) : (day = day);
      (month < 10) ? (month = '0' + month) : (month = month);
      this.selected_date = year.toString() + '-' + month + '-' + day;
      this.selected_date_display = day + '/' + month + '/' + year;
    } else {
      let day: any = new Date(args).getDate();
      let month: any = new Date(args).getMonth() + 1;
      let year = new Date(args).getFullYear();
      (day < 10) ? (day = '0' + day) : (day = day);
      (month < 10) ? (month = '0' + month) : (month = month);
      this.selected_date = year.toString() + '-' + month + '-' + day;
      this.selected_date_display = day + '/' + month + '/' + year;
    }
    let count = 0;
    for (let item in this.events_data) {
      if (this.events_data[item].datum === this.selected_date) {
        count = count + 1;
        let start_time = new Date(
          this.events_data[item].monitorStartTime * 1000
        );
        const diff = new Date(start_time).getTimezoneOffset();
        start_time.setMinutes(start_time.getMinutes() - diff);
        let end_time = new Date(this.events_data[item].monitorEndTime * 1000);
        end_time.setMinutes(end_time.getMinutes() - diff);
        let st = start_time.toISOString().split('.')[0];
        let times = st.split('T')[1].split(':');
        let et = end_time.toISOString().split('.')[0];
        let e_times = et.split('T')[1].split(':');
        let s_hour = times[0];
        let s_min = times[1];
        let s_time = s_hour + ':' + s_min;
        let e_hour = e_times[0];
        let e_min = e_times[1];
        let e_time = e_hour + ':' + e_min;
        // var hour = (this.events_data[item].duration / 3600);
        // let margin_left;
        let float = 'left';
        let width = '-webkit-fill-available';
        let margin_top = '0px';
        let padding_top = '1px';
        // let res = hour / 0.5;
        if (parseInt(this.events_data[item].duration) <= 900) {
          float = 'center';
          width = 'none';
          margin_top = '0px';
          padding_top = 'none';
        }
        if (this.events_data[item].eventType === 'darkblue') {
          width = 'none';
          let translatedText;
          let transFlag = false;
          for (let key in this.dropdown) {
            if (this.dropdown[key].languageKey) {
              if (this.events_data[item].fkEventDetail === this.dropdown[key].text) {
                translatedText = this.dropdown[key].languageKey;
                transFlag = true;
                break;
              }
            } else {
              translatedText = this.events_data[item].fkEventDetail;
            }
          }
          let resultText;
          if (transFlag) {
            resultText = await this.getEventTranslation(translatedText);
          } else {
            resultText = this.events_data[item].fkEventDetail;
          }
          this.events.push({
            moveDisabled: true,
            eventDetailID: this.events_data[item].eventDetail,
            id: this.events_data[item].eventID,
            start: st,
            end: et,
            remarks: this.events_data[item].remarks,
            text:
              (this.events_data[item].remarks) ?
                this.events_data[item].fkEventDetail +
                '   ' +
                s_time +
                ' - ' +
                e_time + '   ' + this.events_data[item].remarks : this.events_data[item].fkEventDetail +
                '   ' +
                s_time +
                ' - ' +
                e_time,
            backColor: '#24597A',
            fontColor: 'white',
            html: `<div style="padding-top:${padding_top}"><b style="width:${width};display:inline-flex;float:left;">  ${resultText} 
            `+ '     ' + `` + '  ' + `</b>   <b style="margin-top: ${margin_top};float:right;">${s_time} ` + ' - ' + `
            ${e_time}</b>
            </b>
            <a style="float:${float};font-size:15px;">
            ${(this.events_data[item]?.remarks != null) ? this.events_data[item]?.remarks : ''}
            </a></div>
            `
          });
        } else {
          let translatedText;
          let transFlag = false;
          for (let key in this.green_categories) {

            if (this.green_categories[key].languageKey) {
              if (this.events_data[item].fkEventDetail === this.green_categories[key].text) {
                translatedText = this.green_categories[key].languageKey;
                transFlag = true;
                break;
              }
            } else {
              translatedText = this.events_data[item].fkEventDetail;
            }
          }
          let resultText;
          if (transFlag) {
            resultText = await this.getEventTranslation(translatedText);
          } else {
            resultText = this.events_data[item].fkEventDetail;
          }
          this.events.push({
            moveDisabled: true,
            id: this.events_data[item].eventID,
            categorie: this.events_data[item].eventDetail,
            start: st,
            end: et,
            remarks: this.events_data[item].meerwerk,
            meerwerk: this.events_data[item].remarks,
            text:
              (this.events_data[item].remarks) ?
                this.events_data[item].orderID + '   ' + s_time + ' - ' + e_time + '    ' + `${resultText}` : this.events_data[item].orderID + '   ' + s_time + ' - ' + e_time,
            html: `<div style="padding-top:${padding_top}"><b style="width:${width};display:inline-flex;float:left;">  ${this.events_data[item].orderID} 
            `+ '     ' + `` + '  ' + `  </b>   <b style="margin-top: ${margin_top};float:right;"> ${s_time} ` + ' - ' + `
            ${e_time}</b>
          
            <a style="float:${float};font-size:15px;">
            ${(this.events_data[item]?.fkEventDetail != null) ? resultText : ''}
            </a></div>
            `,
            backColor: 'green',
            fontColor: 'white',
          });
        }
      }
    }
  }

  set_dates(item: string) {
    let last_date: any = this.slider_values[this.slider_values.length - 1].start;
    let first_date = this.slider_values[0].start;
    let focus_flag = false;
    if (item === '1') {
      this.events = [];
      var date = new Date();
      date.setDate(date.getDate() + 84); // Set now + 30 days as the new date
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      let future_date = new DayPilot.Date(date);
      let lastdate = last_date.addDays(1);
      if (lastdate <= future_date) {
        this.slider_values = [];
        for (let i = 1; i < 8; i++) {
          let m;
          let day = last_date.addDays(i);
          let first = DayPilot.Date.today();
          let flag = false;
          if (day == first) {
            flag = true;
          }
          let tab_date;
          if (this.language == 'Dutch') {
            var date = new Date(day.toString());
            let dutch_day = this.dutch_days[date.getDay()].slice(0, 2);
            let temp = day.getDay();
            m = dutch_day + ' ' + temp;
            tab_date = dutch_day + ' ' + temp + '/' + (day.getMonth() + 1);
          } else if (this.currentlang === 'Spanish') {
            var date = new Date(day.toString());
            let spanish_day = this.spanish_days[date.getDay()].slice(0, 2);
            let temp = day.getDay();
            m = spanish_day + ' ' + temp;
            tab_date = spanish_day + ' ' + temp + '/' + (day.getMonth() + 1);
          } else {
            var date = new Date(day.toString());
            let english_day = this.english_days[date.getDay()].slice(0, 2);
            let temp = day.getDay();
            m = english_day + ' ' + temp;
            tab_date = english_day + ' ' + temp + '/' + (day.getMonth() + 1);
          }
          this.slider_values.push({
            start: day,
            lang_day: m,
            tab_date: tab_date,
            name: day.toString('dd-MM-yy'),
            date: day.toString('yyyy-MM-dd'),
            today: flag,
            focus: false,
          });
        }
        this.Calculate_WeekNumber();
      }
      this.getDuration();
    } else if (item === '0') {
      this.events = [];
      var date = new Date();
      date.setDate(date.getDate() - this.numberOfDaysHistory);; // Set now - 60 days as the new date
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      let past_date = new DayPilot.Date(date);
      if (past_date <= first_date) {
        this.slider_values = [];
        let d: any = new Date(first_date).toISOString().split('T')[0];
        for (let i = 6; 0 <= i && i <= 7; i--) {
          const dt = new Date(Date.parse(d) - 24 * 60 * 60 * 1000 * i);
          let days: any = dt.toLocaleDateString('sv', { timeZone: 'UTC' });
          const dayss = new Date(dt).getDay();
          let year = new Date(dt).getFullYear();
          let month: any = new Date(dt).getMonth() + 1;
          let day: any = new Date(dt).getDate();
          let today = new Date();
          let date2 = new Date(year, month, day);
          today.setHours(0, 0, 0, 0);
          let flag = false;
          if (today == date2) {
            flag = true;
          }
          month < 10 ? (month = '0' + month) : month;
          day < 10 ? (day = '0' + day) : day;
          let date = day + '-' + month + '-' + year.toString().slice(2, 4);
          let temp_date = year + '-' + month + '-' + day;
          let name;
          if (this.language === 'English') {
            if (dayss == 1) {
              name = 'Mo ' + day;
            } else if (dayss == 2) {
              name = 'Tu ' + day;
            } else if (dayss == 3) {
              name = 'We ' + day;
            } else if (dayss == 4) {
              name = 'Th ' + day;
            } else if (dayss == 5) {
              name = 'Fr ' + day;
            } else if (dayss == 6) {
              name = 'Sa ' + day;
            } else {
              name = 'Su ' + day;
            }
          } else if (this.language === 'Spanish') {
            if (dayss == 1) {
              name = 'lu ' + day;
            } else if (dayss == 2) {
              name = 'ma ' + day;
            } else if (dayss == 3) {
              name = 'mi ' + day;
            } else if (dayss == 4) {
              name = 'ju ' + day;
            } else if (dayss == 5) {
              name = 'vi ' + day;
            } else if (dayss == 6) {
              name = 'sá ' + day;
            } else {
              name = 'do ' + day;
            }
          } else {
            if (dayss == 1) {
              name = 'ma ' + day;
            } else if (dayss == 2) {
              name = 'di ' + day;
            } else if (dayss == 3) {
              name = 'wo ' + day;
            } else if (dayss == 4) {
              name = 'do ' + day;
            } else if (dayss == 5) {
              name = 'vr ' + day;
            } else if (dayss == 6) {
              name = 'za ' + day;
            } else {
              name = 'zo ' + day;
            }
          }
          let tab_date = name + '/' + month;
          this.slider_values.push({
            start: new DayPilot.Date(days),
            tab_date: tab_date,
            lang_day: name,
            name: date,
            date: temp_date,
            today: flag,
            focus: false,
          });
        }
        this.Calculate_WeekNumber();
      }
      this.getDuration();
    } else {
      this.slider_values.forEach((element: any, index: any) => {
        if (element.focus == true) {
          focus_flag = true;
        }
        let temp = element.lang_day.split(' ');
        let temp_tabdate = element.tab_date.split(' ');
        if (index == 0) {
          if (this.language === 'Dutch') {
            ((element.lang_day = 'ma ' + temp[1]),
              (element.tab_date = 'ma ' + temp_tabdate[1]))
          } else if (this.language === 'English') {
            ((element.lang_day = 'mo ' + temp[1]),
              (element.tab_date = 'mo ' + temp_tabdate[1]));
          } else {
            ((element.lang_day = 'lu ' + temp[1]),
              (element.tab_date = 'lu ' + temp_tabdate[1]));
          }
        } else if (index == 1) {
          if (this.language === 'Dutch') {
            ((element.lang_day = 'di ' + temp[1]),
              (element.tab_date = 'di ' + temp_tabdate[1]))
          } else if (this.language === 'English') {
            ((element.lang_day = 'tu ' + temp[1]),
              (element.tab_date = 'tu ' + temp_tabdate[1]));
          } else {
            ((element.lang_day = 'ma ' + temp[1]),
              (element.tab_date = 'ma ' + temp_tabdate[1]));
          }
        } else if (index == 2) {
          if (this.language === 'Dutch') {
            ((element.lang_day = 'wo ' + temp[1]),
              (element.tab_date = 'wo ' + temp_tabdate[1]))
          } else if (this.language === 'English') {
            ((element.lang_day = 'we ' + temp[1]),
              (element.tab_date = 'we ' + temp_tabdate[1]));
          } else {
            ((element.lang_day = 'mi ' + temp[1]),
              (element.tab_date = 'mi ' + temp_tabdate[1]));
          }
        } else if (index == 3) {
          if (this.language === 'Dutch') {
            ((element.lang_day = 'do ' + temp[1]),
              (element.tab_date = 'do ' + temp_tabdate[1]))
          } else if (this.language === 'English') {
            ((element.lang_day = 'th ' + temp[1]),
              (element.tab_date = 'th ' + temp_tabdate[1]));
          } else {
            ((element.lang_day = 'ju ' + temp[1]),
              (element.tab_date = 'ju ' + temp_tabdate[1]));
          }
        } else if (index == 4) {
          if (this.language === 'Dutch') {
            ((element.lang_day = 'vr ' + temp[1]),
              (element.tab_date = 'vr ' + temp_tabdate[1]))
          } else if (this.language === 'English') {
            ((element.lang_day = 'fr ' + temp[1]),
              (element.tab_date = 'fr ' + temp_tabdate[1]));
          } else {
            ((element.lang_day = 'vi ' + temp[1]),
              (element.tab_date = 'vi ' + temp_tabdate[1]));
          }
        } else if (index == 5) {
          if (this.language === 'Dutch') {
            ((element.lang_day = 'za ' + temp[1]),
              (element.tab_date = 'za ' + temp_tabdate[1]))
          } else if (this.language === 'English') {
            ((element.lang_day = 'sa ' + temp[1]),
              (element.tab_date = 'sa ' + temp_tabdate[1]));
          } else {
            ((element.lang_day = 'sá ' + temp[1]),
              (element.tab_date = 'sá ' + temp_tabdate[1]));
          }
        } else {
          if (this.language === 'Dutch') {
            ((element.lang_day = 'zo ' + temp[1]),
              (element.tab_date = 'zo ' + temp_tabdate[1]))
          } else if (this.language === 'English') {
            ((element.lang_day = 'su ' + temp[1]),
              (element.tab_date = 'su ' + temp_tabdate[1]));
          } else {
            ((element.lang_day = 'do ' + temp[1]),
              (element.tab_date = 'do ' + temp_tabdate[1]));
          }
        }
      });
    }
    let focusedvalue: any;
    this.slider_values.forEach((item: any) => {
      if (
        focus_flag == false &&
        new Date(item.date).setHours(0, 0, 0, 0) ===
        new Date().setHours(0, 0, 0, 0)
      ) {
        item.today = true;
      }
      if (item.focus === true) {
        focusedvalue = item;
      }
    });
    this.Table_View.forEach((item: any) => {
      if (item.name === 'LOCATION') {
        for (let j = 0; j < item.selectBoxArray.length; j++) {
          item.selectBoxArray[j].selectedValue = '';
        }
      }
    });
    this.getCalendarEvents(focusedvalue?.start)
  }

  Calculate_WeekNumber() {
    let currentdate: any = new Date(this.slider_values[6].date);
    var oneJan: any = new Date(currentdate.getFullYear(), 0, 1);
    var numberOfDays = Math.floor(
      (currentdate - oneJan) / (24 * 60 * 60 * 1000)
    );
    this.week_number = Math.ceil((currentdate.getDay() + 1 + numberOfDays) / 7);
  }

  clear_updateFields() {
    this.dropdown_values = [];
    this.placeholder = [];
    // this.updateForm.reset({});
    // this.registrationForm.reset({});
  }

  setvaidationForUpdate(id: HTMLButtonElement) {
    if (this.customerID === '3' && this.updatepopup_flag == false) {
      let projectnumber = this.customerID === '3' && this.updateForm.value.projectExtention && this.updateForm.value.projectExtention != '' && this.updateForm.value.projectExtention != 'PE' ? this.updateForm.value.project + '-' + this.updateForm.value.projectExtention : this.updateForm.value.project
      let payload = {
        projectnumber: projectnumber
      }
      this.apiservice.validateprojectnumber(payload).subscribe(res => {
        if (res.response === 'success') {
          this.Update_event(id)
        }
        if (res.response === 'error') {
          let errormsg;
          if (this.currentlang === 'Dutch') {
            errormsg = 'Ongeldig projectnummer'
          }
          else if (this.currentlang === 'English') {
            errormsg = 'Invalid project number'
          }
          else if (this.currentlang === 'Spanish') {
            errormsg = 'número de proyecto no válido'

          } else { }
          this.toastrService.error(errormsg, 'Error!');
          // this.updateForm.get('project').reset();
          // this.updateForm.get('projectExtention').reset();

        }
      },
        (err) => console.log(err)
      )
    }
    else {
      this.Update_event(id)
    }

  }

  Update_event(id: HTMLButtonElement) {
    const buttonId: any = id;
    this.registrationForm.value.to_time = this.toEndTime
    this.registrationForm.value.from_time = this.fromStartTime
    this.updateForm.value.from_time = this.fromStartTime
    this.updateForm.value.to_time = this.toEndTime
    buttonId.disabled = "true";
    if (this.updateForm.valid) {
      this.updateForm.value.project = this.customerID === '3' && this.updateForm.value.projectExtention != '' && this.updateForm.value.projectExtention != 'PE' && this.updateForm.value.projectExtention ? this.updateForm.value.project + '-' + this.updateForm.value.projectExtention : this.updateForm.value.project

      let minDate = new Date();
      let maxDate = new Date();
      let from_date = new Date(this.registrationForm.value.from_date);
      let to_date = new Date(this.registrationForm.value.to_date);
      if (from_date > to_date) {
        return this.invalidDate = true;
      } else {
        this.invalidDate = false;
      }
      minDate.setHours(this.updateForm.value.from_time.split(":")[0], this.updateForm.value.from_time.split(":")[1], 0);
      maxDate.setHours(
        this.updateForm.value.to_time.split(":")[0],
        this.updateForm.value.to_time.split(":")[1],
        0
      );
      if (minDate > maxDate) {
        return this.invalidTime = true;
      } else {
        this.invalidTime = false;
      }
      let splitted_fromdate = this.updateForm.value.from_date.split('-');
      let splitted_todate = this.updateForm.value.to_date.split('-');
      let f_date =
        splitted_fromdate[2] +
        '-' +
        splitted_fromdate[1] +
        '-' +
        splitted_fromdate[0];
      let t_date =
        splitted_todate[2] +
        '-' +
        splitted_todate[1] +
        '-' +
        splitted_todate[0];
      let f_time = f_date + 'T' + `${this.updateForm.value.from_time}` + ':00';
      let t_time = t_date + 'T' + `${this.updateForm.value.to_time}` + ':00';
      let start_date = Math.floor(new Date(f_time).getTime() / 1000);
      let end_date = Math.floor(new Date(t_time).getTime() / 1000);
      let remarks;
      if (this.updateForm.value.type != 'project') {
        remarks = this.updateForm.value.note;
      } else {
        if (this.dropdown_values?.length > 0) {
          remarks = this.updateForm.value.mail;
        } else if (this.placeholder?.length > 0) {
          this.placeholder?.forEach((element: any) => {
            if (this.updateForm.value?.[element] != '' && this.updateForm.value?.[element] != null) {
              remarks = this.updateForm.value?.[element];
            }
          });
        } else {
          remarks = '';
        }
      }
      let data = {
        eventID: this.updateForm.value.id,
        updateFields: {
          startTime: start_date,
          monitorStartTime: start_date,
          endTime: end_date,
          monitorEndTime: end_date,
          eventDetail:
            this.updateForm.value.type === 'project'
              ? null
              : parseInt(this.updateForm.value.event),
          orderID:
            this.updateForm.value.type === 'project'
              ? this.updateForm.value.project
              : ' ',
          meerwerk: remarks,
          remarks: this.updateForm.value.remark ? this.updateForm.value.remark : remarks
        },
      };
      this.allow_greensubcat && this.updateForm.value.type === 'project'
        ? (data.updateFields.eventDetail = this.updateForm.value.categorie)
        : ' ';
      this.apiservice.create_event(data).subscribe(
        async (data: calendarEvent) => {
          this.modalService.dismissAll();
          if (data.response === 'success') {
            await this.getDays();
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
    }
  }

  setmodal_flag(item: string, searchelement: any) {
    if (item === '1') {
      this.popup_flag = false;
      this.updatepopup_flag = false;
      this.green_categories
        ? (this.Selected_Catgorie = this.green_categories[0]?.value, this.setFormFields(this.Selected_Catgorie, 'add'))
        : '';
      const projectInput: any = document.querySelector("input[name='project']");
      setTimeout(() => {
        projectInput.focus();
        if (this.input_mask != null && this.input_mask != undefined) {
          this.input = new Inputmask(this.input_mask).mask(projectInput);
        }
      }, 400);
    } else {
      // this.kind_value = this.dropdown[0].value;
      this.popup_flag = true;
      this.updatepopup_flag = true;
      this.placeholder = [];
      this.dropdown_values = [];
    }
  }
  getOldDays(data: any, res: any) {
    let body = this.registrationForm.getRawValue()
    let str = this.registrationForm.value.from_date.split("-");
    let newDate = str[2] + '-' + str[1] + '-' + str[0]
    let responseObj = {
      "eventID": res.eventID,
      "customerID": res.customerID,
      "userID": data.userID,
      "eventType": data.actionType,
      "eventDetail": data.actionDetail,
      "orderID": data.orderNr,
      "startTime": data.startTime,
      "endTime": data.endTime,
      "monitorStartTime": data.startTime,
      "monitorEndTime": data.endTime,
      "datapointIDs": "24237,",
      "customerRef": null,
      "reminderDate": null,
      "fkWorkstationID": "0",
      "orderheaderID": null,
      "id": data.actionDetail,
      "fkCustomerID": 3,
      "fkEventType": data.actionType,
      "level": 1,
      "fkEventDetail": res.eventDescription,
      "languageKey": res.languageKey,
      "priority": 2,
      "notificationRole": null,
      "extraLevel": "Standaard;" + body.mail,
      "preventSkipExtraLevel": 0,
      "valueAdding": 1,
      "hidden": null,
      "prefix": res.eventDescription,
      "bgColor": null,
      "datum": newDate,
      "duration": 900,
      "remarks": body.note ? body.note : '',
      "meerwerk": body.mail,
    }
    console.log(responseObj, 'responseobj....#')
    this.oldCalenderEvents.res.push(responseObj);
    let oldData = this.oldCalenderEvents
    this.workLocations = [];
    this.oldCalenderEvents = data;
    if (oldData.response == 'success') {
      this.selectBox = oldData.availableWorkLocation;
      const formGroup: any = {};
      for (let i = 0; i <= 6; i++) {
        formGroup['location' + i] = new FormControl('');
        this.workLocations.push({ item: oldData.availableWorkLocation, selectedValue: '', label: 'location' + i });
      }
      this.locationForm = new FormGroup(formGroup);
      this.events_data = oldData.res;
      this.getCalendarEvents(this.selected_date);
      this.getDuration();
    }
  }

  getDays() {
    this.workLocations = [];
    this.apiservice.getCalendar(this.currentuser.userID).subscribe(
      async (data: calendarEventList) => {
        this.oldCalenderEvents = data;
        if (data.response == 'success') {
          this.selectBox = data.availableWorkLocation;
          this.numberOfDaysHistory = data.numberOfDaysHistory;
          const formGroup: any = {};
          for (let i = 0; i <= 6; i++) {
            formGroup['location' + i] = new FormControl('');
            this.workLocations.push({ item: data.availableWorkLocation, selectedValue: '', label: 'location' + i });
          }
          this.locationForm = new FormGroup(formGroup);
          this.events_data = data.res;
          await this.getCalendarEvents(this.selected_date);
          await this.getDuration();
          this.clear_updateFields();
        }
      },
      (error: any) => {
        console.log(error)
      }
    );
  }



  clear_greendata() {
    this.LandingPageComponent.clear_greendata();
  }

  generatePDF(divRef: any) {
    html2canvas(divRef)
      .then((successRef) => {
        var doc = new jsPDF('p', 'mm', 'a4');
        var img = successRef.toDataURL('image/png');

        // Add image Canvas to PDF
        const bufferX = 5;
        const bufferY = 5;
        const imgProps = (<any>doc).getImageProperties(img);
        const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        doc.addImage(
          img,
          'PNG',
          bufferX,
          bufferY,
          pdfWidth,
          pdfHeight,
          undefined,
          'FAST'
        );
        return doc;
      })
      .then((doc) => doc.save('weekly_summary.pdf'));
  }

  async onSubmit_event(id: HTMLButtonElement) {
    const buttonId: any = id;
    buttonId.disabled = "true";
    this.modalService.dismissAll();
    if (this.activityForm.value.event == 'close') {
      await this.LandingPageComponent.stop(this.green_eventdata, 'close', true);
      localStorage.removeItem('green_eventdata');
      if (this.LandingPageComponent.darkblue_event?.length >= 1) {
        await this.LandingPageComponent.stop(
          this.LandingPageComponent.active_order,
          'darkblue',
          false
        );
      }
      this.CreateDarkBlue();
      localStorage.removeItem('darkblueorder');
    } else if (this.activityForm.value.event == 'yellow') {
      localStorage.removeItem('active_order');
      this.LandingPageComponent.screen_status = false;
      this.LandingPageComponent.downtime_flag = true;
      this.LandingPageComponent.workinghours_flag = false;
      this.LandingPageComponent.unplanned_flag = false;
      this.LandingPageComponent.reports_flag = false;
      this.LandingPageComponent.project_flag = false;
    } else {
      localStorage.removeItem('active_order');
      this.LandingPageComponent.screen_status = false;
      this.LandingPageComponent.downtime_flag = false;
      this.LandingPageComponent.workinghours_flag = false;
      this.LandingPageComponent.unplanned_flag = true;
      this.LandingPageComponent.reports_flag = false;
      this.LandingPageComponent.project_flag = false;
    }
    this.activityForm.reset({});
  }

  async check_green(content: any, datas: any) {
    this.orders = this.LandingPageComponent.orders;
    this.green_eventdata;
    for (let j = 0; j <= this.orders.length - 1; j++) {
      let flag;
      if (
        this.orders[j].eventType == 'green' &&
        this.orders.length >= 1
        //  &&
        // this.LandingPageComponent.active_order.orderID != this.orders[j].orderID
      ) {
        this.green_eventdata = this.orders[j];
        localStorage.setItem(
          'green_eventdata',
          JSON.stringify(this.green_eventdata)
        );
        flag = true;
        break;
      } else {
        flag = false;
      }
    }
    if (this.green_eventdata) {
      this.open(content, '', '');
    } else {
      this.modalService.dismissAll();
      if (this.LandingPageComponent.darkblue_event?.length >= 1) {
        await this.LandingPageComponent.stop(
          this.LandingPageComponent.active_order,
          'darkblue',
          false
        );
      }
      await this.CreateDarkBlue();
      localStorage.removeItem('darkblueorder');
    }
  }

  create_darkblue(content: any, id: HTMLButtonElement) {
    const buttonId: any = id;
    buttonId.disabled = "true";
    if (this.statusForm.value.event) {
      var unixtimestamp = Math.floor(new Date().getTime() / 1000);
      let datas = {
        userID: this.currentuser.userID,
        actionType: 'darkblue',
        actionDetail: this.statusForm.value.event,
        workstationID: this.workstationid,
        StartTime: unixtimestamp,
        monitorStartTime: unixtimestamp,
        remark: this.statusForm.value.note,
      };
      this.check_green(content, datas);
      localStorage.setItem('darkblueorder', JSON.stringify(datas));
    }
  }

  SetKind_dropdown(item: any) {
    this.kind_value = item.target.value;
  }

  async CreateDarkBlue() {
    if (this.statusForm.valid) {
      var unixtimestamp = Math.floor(new Date().getTime() / 1000);
      let datas = {
        userID: this.currentuser.userID,
        actionType: 'darkblue',
        actionDetail: this.statusForm.value.event,
        workstationID: this.workstationid,
        StartTime: unixtimestamp,
        monitorStartTime: unixtimestamp,
        remark: this.statusForm.value.note,
      };
      this.apiservice.addorder(datas).subscribe(
        async (res: Order) => {
          if (res.response == 'success') {
            this.LandingPageComponent.navigate_cal();
            this.modalService.dismissAll();
            if (this.statusForm.value.note.trim() !== '') {
              let data = {
                eventID: res.eventID,
                updateFields: [
                  {
                    remark: this.statusForm.value.note,
                  },
                ],
              };
              this.apiservice
                .addformfield(data)
                .subscribe(async (res: any) => { });
              this.LandingPageComponent.getorders(
                this.currentuser.userID,
                this.workstationid, false
              );
            } else {
              this.LandingPageComponent.getorders(
                this.currentuser.userID,
                this.workstationid, false
              );
            }
          }
        },
        (error: any) => {
          console.log(error)
        }
      );
    }
  }

  Set_slidervalue(item: any) {
    this.slider_values.forEach((element: any) => {
      element.focus = false;
    });
    item.focus = true;
    if (
      new Date(item.date).setHours(0, 0, 0, 0) ===
      new Date().setHours(0, 0, 0, 0)
    ) {
      item.today = true;
    }
    let result = [];
    result.push({ start: item.start, name: '' });
    this.config.columns = result;
    this.columns = result;
    this.getCalendarEvents(item.start);
  }

  columnss(flag: any): any[] {
    this.slider_values = [];
    this.columns = [];
    this.currentlang = localStorage.getItem('language');
    let first = DayPilot.Date.today();
    let first_day = first.firstDayOfWeek();
    for (let i = 1; i < 8; i++) {
      let day = first_day.addDays(i);
      let flag = false;
      if (day === first) {
        flag = true;
      }
      let m;
      let tab_date;
      if (this.currentlang == 'Dutch') {
        var date = new Date(day.toString());
        let dutch_day = this.dutch_days[date.getDay()].slice(0, 2);
        let temp = day.getDay();
        m = dutch_day + ' ' + temp;
        tab_date = dutch_day + ' ' + temp + '/' + (day.getMonth() + 1);
      } else if (this.currentlang === 'Spanish') {
        var date = new Date(day.toString());
        let spanish_day = this.spanish_days[date.getDay()].slice(0, 2);
        let temp = day.getDay();
        m = spanish_day + ' ' + temp;
        tab_date = spanish_day + ' ' + temp + '/' + (day.getMonth() + 1);
      } else {
        var date = new Date(day.toString());
        let english_day = this.english_days[date.getDay()].slice(0, 2);
        let temp = day.getDay();
        m = english_day + ' ' + temp;
        tab_date = english_day + ' ' + temp + '/' + (day.getMonth() + 1);
      }
      this.slider_values.push({
        start: day,
        tab_date: tab_date,
        lang_day: m,
        name: day.toString('dd-MM-yy'),
        date: day.toString('yyyy-MM-dd'),
        today: flag,
        focus: flag,
      });
    }
    this.slider_values.forEach((element: any) => {
      if (element.today) {
        this.todayDetails = element;
      }
    });
    if (flag == '0') {
      this.getDays();
    }
    this.columns.push({ start: first, name: ' ' });
    this.lastDayOfTheWeek = this.columns[this.columns.length - 1].start;
    this.Calculate_WeekNumber();
    return this.columns;
  }

  async getDuration() {
    let hour: number = 0;
    let min: number = 0;
    this.projects = [];
    this.column_total = [];
    for (let data in this.slider_values) {
      this.slider_values[data].event = [];
      let duration = 0;
      let len = this.events_data.length - 1;
      for (let item in this.events_data) {
        if (this.slider_values[data].date === this.events_data[item].datum && this.events_data[item].fkEventDetail != 'Pauze') {
          this.slider_values[data].event.push({
            orderID: this.events_data[item].orderID,
            event: this.events_data[item].fkEventDetail,
            eventType: this.events_data[item].eventType,
            duration: this.events_data[item].duration,
            date: this.events_data[item].datum,
          });
          this.projects.push({
            orderID: this.events_data[item].orderID,
            event: this.events_data[item].fkEventDetail,
            eventType: this.events_data[item].eventType,
          });
          duration = duration + parseInt(this.events_data[item].duration);
        }
        if (len === parseInt(item)) {
          if (duration != 0 && duration >= 0) {
            var h = Math.floor(duration / 3600);
            var m = Math.floor((duration % 3600) / 60);
            var hDisplay: any = h >= 10 || h == undefined ? h : '0' + h;
            var mDisplay: any = m >= 10 ? m : '0' + m;
            let total = hDisplay + ':' + mDisplay;
            hour = parseInt(hDisplay) + hour;
            min = parseInt(mDisplay) + min;
            this.slider_values[data].total = total;
            this.column_total.push(total);
            break;
          } else {
            this.column_total.push('00:00');
            this.slider_values[data].total = '00:00';
          }
        }
      }
    }
    let clean = this.projects.filter(
      (arr: any, index: any, self: any) =>
        index ===
        self.findIndex(
          (t: any) => t.event === arr.event
        )
    );
    this.projects = clean;
    let temp = Math.floor(min / 60);
    let minutes = min % 60;
    hour = temp + hour;
    var hDisplay: any = hour >= 10 || hour == undefined ? hour : '0' + hour;
    var mDisplay: any =
      minutes >= 10 || minutes == undefined ? minutes : '0' + minutes;
    this.Overall_Total = hDisplay + ':' + mDisplay;
    this.Calculate_Table_Values();
  }

  Calculate_Table_Values() {
    this.Table_View = [];
    let hour: number = 0;
    let min: number = 0;
    for (let data in this.projects) {
      let temp = [];
      let duration = 0;
      for (let item = 0; item < this.slider_values.length; item++) {
        duration = 0;
        if (this.slider_values[item].event?.length > 0) {
          let len = this.slider_values[item].event.length - 1;
          for (let x = 0; x < this.slider_values[item].event.length; x++) {
            if (
              this.slider_values[item].event[x].event ===
              this.projects[data].event &&
              this.slider_values[item].event[x].date ===
              this.slider_values[item].date
            ) {
              duration =
                duration + parseInt(this.slider_values[item].event[x].duration);
            }
            if (len === (x)) {
              if (duration != 0) {
                var h = Math.floor(duration / 3600);
                var m: any = (duration % 3600) / 60;
                m < 1 && m != 0 ? (m = 1) : (m = Math.floor(m));
                var hDisplay: any = h >= 10 || h == undefined ? h : '0' + h;
                var mDisplay: any = m >= 10 || m == undefined ? m : '0' + m;
                let total = hDisplay + ':' + mDisplay;
                hour = parseInt(hDisplay) + hour;
                min = parseInt(mDisplay) + min;
                temp.push(total);
                break;
              } else {
                temp.push('00:00');
              }
            }
          }
        } else {
          temp.push('00:00');
        }
      }
      let y = Math.floor(min / 60);
      let minutes = min % 60;
      hour = y + hour;
      var hDisplay: any = hour >= 10 || hour == undefined ? hour : '0' + hour;
      var mDisplay: any =
        minutes >= 10 || minutes == undefined ? minutes : '0' + minutes;
      let a = hDisplay + ':' + mDisplay;
      let name;
      this.projects[data].eventType == 'green'
        ? (name = this.projects[data].orderID)
        : (name = this.projects[data].event);
      this.Table_View.push({
        orderID: this.projects[data].orderID,
        name: this.projects[data].event,
        eventType: this.projects[data].eventType,
        duration: temp,
        total: a,
      });
      hour = 0;
      min = 0;
    }
    let temp_array = [];
    let h_array = [];
    let m_array = [];
    let tot_hour: any[] = [];
    let tot_min: any[] = [];
    let duration: number = 0;
    let projects = [];
    let otherhours = [];
    if (this.Table_View.length != 0) {
      let m = 0;
      let length = this.Table_View.length - 1;
      let t = this.Table_View.length * 7;
      let count = 0;
      for (let k = 0; count <= t; k++) {
        for (let i = 0; i <= this.Table_View[k].duration.length - 1; i++) {
          if (this.Table_View[k]?.duration[m]) {
            let x = this.Table_View[k]?.duration[m].split(':');
            h_array.push(parseInt(x[0]));
            m_array.push(parseInt(x[1]));
            duration = duration + this.Table_View[k].duration[m];
            break;
          }
        }
        count++;
        if (k == length) {
          let hour = h_array.reduce((partial, a) => partial + a, 0);
          let min = m_array.reduce((partial, a) => partial + a, 0);
          let y = Math.floor(min / 60);
          let minutes = min % 60;
          hour = y + hour;
          let a = hour + ':' + minutes;
          temp_array.push(a);
          k = -1;
          m++;
          h_array = [];
          m_array = [];
          duration = 0;
        }
      }
    } else {
      tot_hour = [parseInt('00')];
      tot_min = [parseInt('00')];
      temp_array = [
        '00:00',
        '00:00',
        '00:00',
        '00:00',
        '00:00',
        '00:00',
        '00:00',
      ];
    }
    if (this.Table_View.length > 0) {
      this.column_total.forEach((element: any) => {
        let temp = element.split(':');
        tot_hour.push(parseInt(temp[0]));
        tot_min.push(parseInt(temp[1]));
      });
    }
    let t_hour = tot_hour.reduce((partial, a) => partial + a, 0);
    let t_min = tot_min.reduce((partial, a) => partial + a, 0);
    let y = Math.floor(t_min / 60);
    let minutes = t_min % 60;
    let z = y + t_hour;
    var hDisplay: any = z >= 10 || z == undefined ? z : '0' + z;
    var mDisplay: any =
      minutes >= 10 || minutes == undefined ? minutes : '0' + minutes;
    let a = hDisplay + ':' + mDisplay;
    this.Table_View.push({
      name: 'Total per day',
      orderID: null,
      eventType: null,
      duration: this.column_total,
      total: a,
    });
    let footer_array = [];
    if (this.Table_View.length > 0) {
      for (let item in this.Table_View) {
        if (this.Table_View[item].eventType == 'green') {
          projects.push(this.Table_View[item]);
        } else if (this.Table_View[item].eventType == 'darkblue') {
          otherhours.push(this.Table_View[item]);
        } else {
          footer_array.push(this.Table_View[item]);
        }
      }
    }
    this.Table_View = [];
    this.Table_View.push({
      name: 'LOCATION',
      selectBoxArray: this.workLocations,
      orderID: null,
      eventType: null,
      duration: [...this.slider_values],
      total: null,
    });
    projects.length > 0
      ? Array.prototype.push.apply(this.Table_View, projects)
      : '';
    otherhours.length > 0
      ? Array.prototype.push.apply(this.Table_View, otherhours)
      : '';
    footer_array.length > 0
      ? Array.prototype.push.apply(this.Table_View, footer_array)
      : '';
  }

  setValiadtion(id: HTMLButtonElement) {
    const buttonId: any = id;
    buttonId.disabled = true;
    if (this.customerID === '3' && this.popup_flag == false) {
      let projectnumber = this.customerID === '3' && this.registrationForm.value.projectExtention != 'PE' ? this.registrationForm.value.project + '-' + this.registrationForm.value.projectExtention : this.registrationForm.value.project

      let payload = {
        projectnumber: projectnumber
      }
      this.apiservice.validateprojectnumber(payload).subscribe(res => {
        if (res.response === 'success') {
          this.Add_event(id)
        }
        if (res.response === 'error') {
          let errormsg;
          if (this.currentlang === 'Dutch') {
            errormsg = 'Ongeldig projectnummer'
          }
          else if (this.currentlang === 'English') {
            errormsg = 'Invalid project number'
          }
          else if (this.currentlang === 'Spanish') {
            errormsg = 'número de proyecto no válido'

          } else { }
          this.toastrService.error(errormsg, 'Error!');
        }

      },
        (err) => console.log(err)
      )
    }
    else {
      this.Add_event(id)
    }

  }
  async Add_event(id: HTMLButtonElement) {
    const buttonId: any = id;
    buttonId.disabled = true;
    this.registrationForm.value.to_time = this.toEndTime
    this.registrationForm.value.from_time = this.fromStartTime
    this.updateForm.value.from_time = this.fromStartTime
    this.updateForm.value.to_time = this.toEndTime
    let minDate = new Date();
    let maxDate = new Date();
    let from_date = new Date(this.registrationForm.value.from_date);
    let to_date = new Date(this.registrationForm.value.to_date);
    if (from_date > to_date) {
      return this.invalidDate = true;
    } else {
      this.invalidDate = false;
    }
    minDate.setHours(this.registrationForm.value.from_time.split(":")[0], this.registrationForm.value.from_time.split(":")[1], 0);
    maxDate.setHours(
      this.registrationForm.value.to_time.split(":")[0],
      this.registrationForm.value.to_time.split(":")[1],
      0
    );
    if (minDate > maxDate) {
      return this.invalidTime = true;
    } else {
      this.invalidTime = false;
    }

    if (this.popup_flag) {
      this.input_item = true;
    } else {
      // this.input_item = true;
      if (this.input_mask == null || this.input_mask == undefined) {
        this.input_item = true;
      }
    }
    if (this.registrationForm.valid && this.input_item) {
      if (this.popup_flag == true && !this.registrationForm.value.event) {
        return;
      }
      this.registrationForm.value.project = this.customerID === '3' && this.registrationForm.value.projectExtention && this.registrationForm.value.projectExtention != '' && this.registrationForm.value.projectExtention != 'PE' ? this.registrationForm.value.project + '-' + this.registrationForm.value.projectExtention : this.registrationForm.value.project
      let splitted_fromdate = this.registrationForm.value.from_date.split('-');
      let splitted_todate = this.registrationForm.value.to_date.split('-');
      let f_date =
        splitted_fromdate[2] +
        '-' +
        splitted_fromdate[1] +
        '-' +
        splitted_fromdate[0];
      let t_date =
        splitted_todate[2] +
        '-' +
        splitted_todate[1] +
        '-' +
        splitted_todate[0];
      let f_time =
        f_date + 'T' + `${this.registrationForm.value.from_time}` + ':00';
      let t_time =
        t_date + 'T' + `${this.registrationForm.value.to_time}` + ':00';
      let start_date = Math.floor(new Date(f_time).getTime() / 1000);
      let end_date = Math.floor(new Date(t_time).getTime() / 1000);
      let data = {
        userID: this.currentuser.userID,
        orderNr: !this.popup_flag ? this.registrationForm.value.project : '',
        actionType: !this.popup_flag ? 'green' : 'darkblue',
        actionDetail: !this.popup_flag
          ? this.registrationForm.value.categorie
          : this.registrationForm.value.event,
        workstationID: this.workstationid,
        startTime: start_date,
        endTime: end_date,
      };
      this.apiservice.create_event(data).subscribe(
        async (res: calendarEvent) => {
          this.modalService.dismissAll();
          if (res.response == 'success') {
            this.eventId = res.eventID;
            if (!this.popup_flag) {
              await this.getOldDays(data, res)
              await this.PostFormFields(this.eventId, null, this.registrationForm.value);
              await this.getDays();
            } else {
              if (
                this.registrationForm.value.note != '' &&
                this.registrationForm.value.note != null
              ) {
                await this.PostFormFields(
                  this.eventId,
                  this.registrationForm.value.note,
                  null
                );
              }
              await this.getOldDays(data, res)
              await this.getDays();
            }
          }
        },
        (error) => { }
      );
    } else {
    }
  }

  async PostFormFields(eventId: string, remark: any, form: any) {
    let data: any = {
      eventID: eventId,
      updateFields: [],
    };
    this.placeholder?.forEach((element: any) => {
      if (form?.[element] != '' && form?.[element] != null) {
        data.updateFields.push({ remarks: form?.[element] });
      }
    });

    if (form?.mail != '' && form?.mail != null) {
      data.updateFields.push({ meerwerk: form?.mail })
    }
    if (form?.remark != '' && form?.remark != null) {
      data.updateFields.push({ remarks: form?.remark });
    }
    (remark) ? data.updateFields.push({ remarks: remark }) : '';
    await this.apiservice.addformfield(data).toPromise();

  }

  openXl(content: any) {
    let locations: any = localStorage.getItem("locations");
    (locations) ? locations = JSON.parse(locations) : '';
    this.Table_View.forEach((item: any) => {
      if (item.name === 'LOCATION') {
        for (let j = 0; j < item.duration.length; j++) {
          if (locations) {
            for (let k = 0; k < locations.length; k++) {
              if (locations[k].date == item.duration[j].date) {
                item.selectBoxArray[j].selectedValue = locations[k].workLocation;
              }
            }
          }
        }
      }
    });
    this.modalService.open(content, { size: 'xl' });
  }

  async open(content: any, args: any, search: any) {
    this.modalService.open(content);
    if (args?.start) {
      this.start_date = args.start.value;
      this.end_date = args.end.value;
    } else if (args?.e) {
      this.start_date = args.e.data.start;
      this.end_date = args.e.data.end;
    } else {
    }
  }


  public startTimerChange(value: any, type: string): void {
    if (type == 'min') {
      let starttotalmin = ((+this.fromStartTime.split(':')[0]) * 60) + (+value);
      let startHour: any = Math.floor(starttotalmin / 60);
      let startMin: any = starttotalmin % 60;
      startHour = startHour < 10 ? '0' + startHour : startHour
      startMin = startMin < 10 ? '0' + startMin : startMin
      // duration
      let durationMinutes = (+this.duarionTime.split(':')[0] * 60) + (+this.duarionTime.split(':')[1]);
      let duration = (durationMinutes) + (starttotalmin)
      let fhours: any = Math.floor(duration / 60);
      let fminutes: any = duration % 60;
      fhours = fhours < 10 ? '0' + fhours : fhours
      fminutes = fminutes < 10 ? '0' + fminutes : fminutes
      this.toEndTime = fhours + ':' + fminutes
      this.fromStartTime = startHour + ':' + startMin
    }
    else {
      let starttotalmin = (+this.fromStartTime.split(':')[1]) + (60 * (+value))
      let startHour: any = Math.floor(starttotalmin / 60);
      let startMin: any = starttotalmin % 60;
      startHour = startHour < 10 ? '0' + startHour : startHour
      startMin = startMin < 10 ? '0' + startMin : startMin
      // duration
      let durationMinutes = (+this.duarionTime.split(':')[0] * 60) + (+this.duarionTime.split(':')[1]);
      let duration = (durationMinutes) + (starttotalmin)
      let fhours: any = Math.floor(duration / 60);
      let fminutes: any = duration % 60;
      fhours = fhours < 10 ? '0' + fhours : fhours
      fminutes = fminutes < 10 ? '0' + fminutes : fminutes
      this.toEndTime = fhours + ':' + fminutes
      this.fromStartTime = startHour + ':' + startMin
    }
  }

  public endTimerChange(value: any, type: string): void {
    if (type == 'min') {
      let starttotalmin = ((+this.fromStartTime.split(':')[0]) * 60) + (+this.fromStartTime.split(':')[1]);
      let endTotalMin = ((+this.toEndTime.split(':')[0]) * 60) + (+value)
      let startHour: any = Math.floor(endTotalMin / 60);
      let startMin: any = endTotalMin % 60;
      startHour = startHour < 10 ? '0' + startHour : startHour
      startMin = startMin < 10 ? '0' + startMin : startMin
      // duration
      let duration = (endTotalMin) - (starttotalmin)
      let fhours: any = Math.floor(duration / 60);
      let fminutes: any = duration % 60;
      fhours = fhours < 10 ? '0' + fhours : fhours
      fminutes = fminutes < 10 ? '0' + fminutes : fminutes
      this.duarionTime = fhours + ':' + fminutes
      this.toEndTime = startHour + ':' + startMin
    }
    else {
      let starttotalmin = ((+this.fromStartTime.split(':')[0]) * 60) + (+this.fromStartTime.split(':')[1]);
      let endTotalMin = ((+value) * 60) + (+this.toEndTime.split(':')[1])
      let startHour: any = Math.floor(endTotalMin / 60);
      let startMin: any = endTotalMin % 60;
      startHour = startHour < 10 ? '0' + startHour : startHour
      startMin = startMin < 10 ? '0' + startMin : startMin
      // duration
      let duration = (endTotalMin) - (starttotalmin)
      let fhours: any = Math.floor(duration / 60);
      let fminutes: any = duration % 60;
      fhours = fhours < 10 ? '0' + fhours : fhours
      fminutes = fminutes < 10 ? '0' + fminutes : fminutes
      this.duarionTime = fhours + ':' + fminutes
      this.toEndTime = startHour + ':' + startMin
    }
  }

  public setDurationTime(value: any, type: string): void {
    if (type == 'min') {
      let starttotalmin = ((+this.fromStartTime.split(':')[0]) * 60) + (+this.fromStartTime.split(':')[1]);
      let durationTime = ((+this.duarionTime.split(':')[0]) * 60) + (+value)
      let totalminutes = (+starttotalmin) + (+durationTime)
      let fhours: any = Math.floor(totalminutes / 60);
      let fminutes: any = totalminutes % 60;
      fhours = fhours < 10 ? '0' + fhours : fhours
      fminutes = fminutes < 10 ? '0' + fminutes : fminutes
      this.toEndTime = fhours + ':' + fminutes
    }
    else {
      let starttotalmin = ((+this.fromStartTime.split(':')[0]) * 60) + (+this.fromStartTime.split(':')[1]);
      let durationTime = ((+value) * 60) + (+this.duarionTime.split(':')[1])
      let totalminutes = (+starttotalmin) + (+durationTime);
      console.log(totalminutes, 'total')
      let fhours: any = Math.floor(totalminutes / 60);
      let fminutes: any = totalminutes % 60;
      fhours = fhours < 10 ? '0' + fhours : fhours
      fminutes = fminutes < 10 ? '0' + fminutes : fminutes
      this.toEndTime = fhours + ':' + fminutes
    }
  }

  addAndRemoveStartHours(hoursType: string) {
    let count = 60;
    console.log(this.fromStartTime, 'fromStartTime')
    console.log(this.toEndTime, 'fromStartTime')
    if (hoursType == 'add') {
      let totalMinute = (+this.fromStartTime.split(':')[0] * 60) + (+this.fromStartTime.split(':')[1]);
      totalMinute = totalMinute + count;
      let hours: any = Math.floor(totalMinute / 60);
      let minutes: any = totalMinute % 60;
      hours = hours < 10 ? '0' + hours : hours
      minutes = minutes < 10 ? '0' + minutes : minutes
      let ftotalMinutes = (+this.duarionTime.split(':')[0] * 60) + (+this.duarionTime.split(':')[1]);
      let duration = (+ftotalMinutes) + (+totalMinute)
      let fhours: any = Math.floor(duration / 60);
      let fminutes: any = duration % 60;
      fhours = fhours < 10 ? '0' + fhours : fhours
      fminutes = fminutes < 10 ? '0' + fminutes : fminutes
      if (duration < 1440) {
        this.toEndTime = fhours + ":" + fminutes
        this.fromStartTime = hours + ":" + minutes
      }
    }
    else {
      let totalMinute = (+this.fromStartTime.split(':')[0] * 60) + (+this.fromStartTime.split(':')[1]);
      totalMinute = totalMinute - count;
      let hours: any = Math.floor(totalMinute / 60);
      let minutes: any = totalMinute % 60;
      hours = hours < 10 ? '0' + hours : hours
      minutes = minutes < 10 ? '0' + minutes : minutes
      let ftotalMinutes = (+this.duarionTime.split(':')[0] * 60) + (+this.duarionTime.split(':')[1]);
      let duration = (+ftotalMinutes) + (+totalMinute)
      let fhours: any = Math.floor(duration / 60);
      let fminutes: any = duration % 60;
      fhours = fhours < 10 ? '0' + fhours : fhours
      fminutes = fminutes < 10 ? '0' + fminutes : fminutes;
      if (totalMinute > 0) {
        this.toEndTime = fhours + ":" + fminutes
        this.fromStartTime = hours + ":" + minutes
      }
    }
  }
  addAndRemoveStartMinutes(hoursType: string) {
    let count = 15;
    if (hoursType == 'add') {
      let totalMinute = (+this.fromStartTime.split(':')[0] * 60) + (+this.fromStartTime.split(':')[1]);
      totalMinute = totalMinute + count;
      let hours: any = Math.floor(totalMinute / 60);
      let minutes: any = totalMinute % 60;
      hours = hours < 10 ? '0' + hours : hours
      minutes = minutes < 10 ? '0' + minutes : minutes
      let ftotalMinutes = (+this.duarionTime.split(':')[0] * 60) + (+this.duarionTime.split(':')[1]);
      let duration = (+ftotalMinutes) + (+totalMinute)
      let fhours: any = Math.floor(duration / 60);
      let fminutes: any = duration % 60;
      fhours = fhours < 10 ? '0' + fhours : fhours
      fminutes = fminutes < 10 ? '0' + fminutes : fminutes
      if (duration < 1440) {
        this.toEndTime = fhours + ":" + fminutes
        this.fromStartTime = hours + ":" + minutes
      }
    }
    else {
      let totalMinute = (+this.fromStartTime.split(':')[0] * 60) + (+this.fromStartTime.split(':')[1]);
      totalMinute = totalMinute - count;
      let hours: any = Math.floor(totalMinute / 60);
      let minutes: any = totalMinute % 60;
      hours = hours < 10 ? '0' + hours : hours
      minutes = minutes < 10 ? '0' + minutes : minutes
      let ftotalMinutes = (+this.duarionTime.split(':')[0] * 60) + (+this.duarionTime.split(':')[1]);
      let duration = (+ftotalMinutes) + (+totalMinute)
      let fhours: any = Math.floor(duration / 60);
      let fminutes: any = duration % 60;
      fhours = fhours < 10 ? '0' + fhours : fhours
      fminutes = fminutes < 10 ? '0' + fminutes : fminutes
      console.log(totalMinute, 'totalMinute')
      if (totalMinute > 0) {
        this.toEndTime = fhours + ":" + fminutes
        this.fromStartTime = hours + ":" + minutes
      }

    }
  }

  addAndRemoveEndHours(hoursType: string) {
    let count = 60;
    if (hoursType == 'add') {
      let totalMinute = (+this.toEndTime.split(':')[0] * 60) + (+this.toEndTime.split(':')[1]);
      totalMinute = totalMinute + count;
      let hours: any = Math.floor(totalMinute / 60);
      let minutes: any = totalMinute % 60;
      hours = hours < 10 ? '0' + hours : hours
      minutes = minutes < 10 ? '0' + minutes : minutes
      let ftotalMinute = (+this.fromStartTime.split(':')[0] * 60) + (+this.fromStartTime.split(':')[1]);
      let duration = (+totalMinute) - (+ftotalMinute)
      let stop = duration + ftotalMinute
      let fhours: any = Math.floor(duration / 60);
      let fminutes: any = duration % 60;
      fhours = fhours < 10 ? '0' + fhours : fhours
      fminutes = fminutes < 10 ? '0' + fminutes : fminutes
      console.log(stop, 'stop..')
      if (stop < 1440) {
        this.duarionTime = fhours + ":" + fminutes;
        this.toEndTime = hours + ":" + minutes
      }

    }
    else {
      let totalMinute = (+this.toEndTime.split(':')[0] * 60) + (+this.toEndTime.split(':')[1]);
      let ftotalMinute = (+this.fromStartTime.split(':')[0] * 60) + (+this.fromStartTime.split(':')[1]);
      console.log(ftotalMinute, 'ftotalMinute')
      console.log(totalMinute, 'totalMinute')
      totalMinute = (+totalMinute) - (+count);
      if (ftotalMinute < totalMinute) {
        let hours: any = Math.floor(totalMinute / 60);
        let minutes: any = totalMinute % 60;
        hours = hours < 10 ? '0' + hours : hours
        minutes = minutes < 10 ? '0' + minutes : minutes
        this.toEndTime = hours + ":" + minutes
        let duration = (+totalMinute) - (+ftotalMinute)
        let stop = duration + ftotalMinute
        let fhours: any = Math.floor(duration / 60);
        let fminutes: any = duration % 60;
        fhours = fhours < 10 ? '0' + fhours : fhours
        fminutes = fminutes < 10 ? '0' + fminutes : fminutes
        this.duarionTime = fhours + ":" + fminutes;
        this.toEndTime = hours + ":" + minutes

      }
    }





  }
  addAndRemoveEndMinutes(hoursType: string) {
    let count = 15;
    if (hoursType == 'add') {
      let totalMinute = (+this.toEndTime.split(':')[0] * 60) + (+this.toEndTime.split(':')[1]);
      totalMinute = totalMinute + count;
      let hours: any = Math.floor(totalMinute / 60);
      let minutes: any = totalMinute % 60;
      hours = hours < 10 ? '0' + hours : hours
      minutes = minutes < 10 ? '0' + minutes : minutes
      let ftotalMinute = (+this.fromStartTime.split(':')[0] * 60) + (+this.fromStartTime.split(':')[1]);
      let duration = (+totalMinute) - (+ftotalMinute)
      let fhours: any = Math.floor(duration / 60);
      let fminutes: any = duration % 60;
      fhours = fhours < 10 ? '0' + fhours : fhours
      fminutes = fminutes < 10 ? '0' + fminutes : fminutes;
      let stop = duration + ftotalMinute
      if (stop < 1440) {
        this.duarionTime = fhours + ":" + fminutes;
        this.toEndTime = hours + ":" + minutes
      }
    }
    else {
      let totalMinute = (+this.toEndTime.split(':')[0] * 60) + (+this.toEndTime.split(':')[1]);
      let ftotalMinute = (+this.fromStartTime.split(':')[0] * 60) + (+this.fromStartTime.split(':')[1]);
      console.log(ftotalMinute, 'ftotalMinute')
      console.log(totalMinute, 'totalMinute')
      totalMinute = (+totalMinute) - (+count);
      if (ftotalMinute < totalMinute) {
        let hours: any = Math.floor(totalMinute / 60);
        let minutes: any = totalMinute % 60;
        hours = hours < 10 ? '0' + hours : hours
        minutes = minutes < 10 ? '0' + minutes : minutes
        this.toEndTime = hours + ":" + minutes
        let duration = (+totalMinute) - (+ftotalMinute)
        let stop = duration + ftotalMinute
        let fhours: any = Math.floor(duration / 60);
        let fminutes: any = duration % 60;
        fhours = fhours < 10 ? '0' + fhours : fhours
        fminutes = fminutes < 10 ? '0' + fminutes : fminutes
        this.duarionTime = fhours + ":" + fminutes;
        this.toEndTime = hours + ":" + minutes
      }
    }
  }

  addAndRemoveDurationHours(hoursType: string) {
    let count = 60;
    if (hoursType == 'add') {
      let totalMinute = (+this.duarionTime.split(':')[0] * 60) + (+this.duarionTime.split(':')[1]);
      totalMinute = totalMinute + count;
      let hours: any = Math.floor(totalMinute / 60);
      let minutes: any = totalMinute % 60;
      hours = hours < 10 ? '0' + hours : hours
      minutes = minutes < 10 ? '0' + minutes : minutes
      let ftotalMinute = (+this.fromStartTime.split(':')[0] * 60) + (+this.fromStartTime.split(':')[1]);
      let duration = (+totalMinute) + (+ftotalMinute)
      let fhours: any = Math.floor(duration / 60);
      let fminutes: any = duration % 60;
      fhours = fhours < 10 ? '0' + fhours : fhours
      fminutes = fminutes < 10 ? '0' + fminutes : fminutes;
      console.log(duration, 'duration')
      if (duration < 1440) {
        this.toEndTime = fhours + ":" + fminutes;
        this.duarionTime = hours + ":" + minutes
      }

    }
    else {
      let totalMinute = (+this.duarionTime.split(':')[0] * 60) + (+this.duarionTime.split(':')[1]);
      totalMinute = totalMinute - count;
      let hours: any = Math.floor(totalMinute / 60);
      let minutes: any = totalMinute % 60;
      hours = hours < 10 ? '0' + hours : hours
      minutes = minutes < 10 ? '0' + minutes : minutes
      let ftotalMinute = (+this.fromStartTime.split(':')[0] * 60) + (+this.fromStartTime.split(':')[1]);
      let duration = (+totalMinute) + (+ftotalMinute)
      let fhours: any = Math.floor(duration / 60);
      let fminutes: any = duration % 60;
      fhours = fhours < 10 ? '0' + fhours : fhours
      fminutes = fminutes < 10 ? '0' + fminutes : fminutes;
      if (ftotalMinute < duration) {
        this.toEndTime = fhours + ":" + fminutes;
        this.duarionTime = hours + ":" + minutes
      }
    }


  }
  addAndRemoveDurationMinutes(hoursType: string) {
    let count = 15;
    if (hoursType == 'add') {
      let totalMinute = (+this.duarionTime.split(':')[0] * 60) + (+this.duarionTime.split(':')[1]);
      totalMinute = totalMinute + count;
      let hours: any = Math.floor(totalMinute / 60);
      let minutes: any = totalMinute % 60;
      hours = hours < 10 ? '0' + hours : hours
      minutes = minutes < 10 ? '0' + minutes : minutes
      let ftotalMinute = (+this.fromStartTime.split(':')[0] * 60) + (+this.fromStartTime.split(':')[1]);
      let duration = (+totalMinute) + (+ftotalMinute)
      let fhours: any = Math.floor(duration / 60);
      let fminutes: any = duration % 60;
      fhours = fhours < 10 ? '0' + fhours : fhours
      fminutes = fminutes < 10 ? '0' + fminutes : fminutes;
      console.log(duration, 'duration')
      if (duration < 1440) {
        this.toEndTime = fhours + ":" + fminutes;
        this.duarionTime = hours + ":" + minutes
      }
    }
    else {
      let totalMinute = (+this.duarionTime.split(':')[0] * 60) + (+this.duarionTime.split(':')[1]);
      totalMinute = totalMinute - count;
      let hours: any = Math.floor(totalMinute / 60);
      let minutes: any = totalMinute % 60;
      hours = hours < 10 ? '0' + hours : hours
      minutes = minutes < 10 ? '0' + minutes : minutes
      let ftotalMinute = (+this.fromStartTime.split(':')[0] * 60) + (+this.fromStartTime.split(':')[1]);
      let duration = (+totalMinute) + (+ftotalMinute)
      let fhours: any = Math.floor(duration / 60);
      let fminutes: any = duration % 60;
      fhours = fhours < 10 ? '0' + fhours : fhours
      fminutes = fminutes < 10 ? '0' + fminutes : fminutes;
      if (ftotalMinute < duration) {
        this.toEndTime = fhours + ":" + fminutes;
        this.duarionTime = hours + ":" + minutes
      }
    }
  }
}
